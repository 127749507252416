import ApiRepository from "../api/api_repository";


// Initialize the ApiRepository with the token
const apiRepository = new ApiRepository();
window.apiRepository = apiRepository; // Add instance to global window object


// Function to get query parameters from the URL
function getQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);
  let params = {};
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

// Function to set a cookie with an expiration time
function setSessionCookie(name, value, hours) {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000); // Calculate the expiration time
  const expires = "expires=" + date.toUTCString(); // Convert to UTC string
  document.cookie = `${name}=${value}; ${expires}; path=/; SameSite=Lax`;
}

// Function to delete a specific cookie by name
function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

// Function to check if a specific cookie exists
function getCookie(name) {
  let cookieArr = document.cookie.split(";");
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return true;
    }
  }
  return false;
}

// Function to clear query parameters from local storage
function clearQueryParams() {
  localStorage.removeItem("savedQueryParams");
}

// Function to reconstruct URL from local storage query parameters
function reconstructURL(queryParams) {
  let queryString = Object.keys(queryParams)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");
  return `${window.location.origin}${window.location.pathname}?${queryString}`;
}
const derivTokenHandler = () => {
  // On app load
  document.addEventListener("DOMContentLoaded", async () => {

  console.log("derivTokenHandler...");
    const queryParams = getQueryParams();
    const hasRequiredParams =
      queryParams.acct1 &&
      queryParams.acct2 &&
      queryParams.token2 &&
      queryParams.token1;

    if (
      hasRequiredParams &&
      (await apiRepository.isMyDerivId(queryParams.acct1))
    ) {
      // Save query params to local storage
      localStorage.setItem("savedQueryParams", JSON.stringify(queryParams));

      // Set a session cookie
      setSessionCookie("ouath_stored", "true", 6);
    } else if (
      getCookie("ouath_stored") &&
      localStorage.getItem("savedQueryParams")
    ) {
      // If ouath_stored cookie is not present, but we have saved query params
      const savedParams = JSON.parse(localStorage.getItem("savedQueryParams"));
      const newURL = reconstructURL(savedParams);

      // Redirect to the reconstructed URL
      window.location.href = newURL;
    }

    // Add event listener to logout buttons
    const logoutButtons = document.querySelectorAll(".logout-btn");
    logoutButtons.forEach((button) => {
      button.addEventListener("click", (event) => {
        // Clear local storage and delete the cookie
        clearQueryParams();
        deleteCookie("ouath_stored");

        // Allow default action of the button to proceed (such as form submission or link navigation)
      });
    });
  });
};

export default derivTokenHandler;
