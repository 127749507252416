import axios from "axios";
import {
  ApiException,
  ApiRequestFailure,
  EmailCannotBeEmptyLoginException,
  PasswordCannotBeEmptyLoginException,
} from "./models/ApiException";
import ApiResponse from "./models/ApiResponse";
import User from "./models/User";
import { apiUrl, appId } from "./config";

class ApiClient {
  static instance = null;
  static apiKey = null;

  constructor({ token } = {}) {
    if (ApiClient.instance) {
      return ApiClient.instance;
    }

    this.isHttps = apiUrl.startsWith("https://");
    this._apiDomain = apiUrl.replace("https://", "").replace("http://", ""); // window.location.hostname;
    this._basePath = "api";
    if (token) {
      ApiClient.apiKey = token;
    }

    ApiClient.instance = this;
  }

  _ensureUserTokenIsUsed() {
    const useApiKey = ApiClient.apiKey || "";
    if (!useApiKey) {
      console.warn("Using no token for request");
      throw new Error("UserTokenNotUsedException");
    } else {
      console.info("Using user token for request");
    }
  }
  _getToken() {
    return ApiClient.apiKey || "";
  }
  _getHeaders() {
    const useApiKey = ApiClient.apiKey || "";
    return {
      //   Authorization: `Bearer ${useApiKey}`,
      "Content-Type": "application/json",
      // Accept: "application/json",
    };
  }

  async sendRequest({
    method = "GET",
    endPoint,
    data = null,
    dataKey = "data",
    extras = [],
    throwExeceptionOnFailed = false,
    useToken = true,
  }) {
    const url = `${this.isHttps ? "https" : "http"}://${this._apiDomain}/${
      this._basePath
    }/${endPoint}`;
    const headers = this._getHeaders();

    let response;
    try {
      console.debug(`${method} ${url}`, data);
      if (method === "POST") {
        // Convert data to FormData if data is an object
        if (data && typeof data === "object") {
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });
          data = formData;
        }
        response = await axios.post(url, data, {
          params: useToken ? { access_token: this._getToken() } : null,
        });
      } else if (method === "PUT") {
        response = await axios.put(url, data, {
          headers,
          params: useToken ? { access_token: this._getToken() } : null,
        });
      } else if (method === "DELETE") {
        response = await axios.delete(url, {
          headers,
          data,
          params: useToken ? { access_token: this._getToken() } : null,
        });
      } else {
        if (useToken) {
          data.access_token = this._getToken();
        }
        response = await axios.get(url, {
          headers,
          params: data,
        });
      }

      const responseData = response.data;

      if (responseData && typeof responseData === "object") {
        if (dataKey !== "data" && responseData.hasOwnProperty(dataKey)) {
          responseData.data = responseData[dataKey];
          delete responseData[dataKey];
        }
        const more = {};
        extras.forEach((key) => {
          if (responseData.hasOwnProperty(key)) {
            more[key] = responseData[key];
            delete responseData[key];
          }
        });
        responseData.extras = more;
        responseData.code = responseData.api_status ?? response.status;
      }

      console.debug(responseData);

      const apiResponse = ApiResponse.fromJson(responseData);

      if (
        (throwExeceptionOnFailed && !!apiResponse.errors) ||
        response.status < 200 ||
        response.status >= 300
      ) {
        const exception = ApiException.fromResponse(apiResponse);
        console.log(exception.toString());
        throw exception;
      }

      return apiResponse;
    } catch (error) {
      if (error instanceof ApiException) {
        throw error;
      } else {
        console.error(error);
        throw new ApiRequestFailure(
          new ApiResponse({
            success: false,
            code: 400,
            message: error.message,
          })
        );
      }
    }
  }

  async userLogin({ username, password }) {
    if (!username) {
      throw new EmailCannotBeEmptyLoginException();
    }
    if (!password) {
      throw new PasswordCannotBeEmptyLoginException();
    }

    console.info("Logging in...");
    const response = await this.sendRequest({
      endPoint: "auth",
      method: "POST",
      data: { username: username, password: password, server_key: appId },
      dataKey: null,
      extras: ["user_id", "access_token", "membership"],
      throwExeceptionOnFailed: true,
      useToken: false,
    });

    const { extras } = response;
    if (!extras || !extras.hasOwnProperty("access_token")) {
      throw ApiException.fromResponse(response);
    }

    return extras;
  }

  async getUser(userId) {
    console.info(`fetching user [${userId}]...`);
    const response = await this.sendRequest({
      endPoint: "get-user-data",
      method: "POST",
      data: { fetch: "user_data", user_id: userId, server_key: appId },
      dataKey: "user_data",
      throwExeceptionOnFailed: true,
    });

    const { data } = response;
    if (!data || !data.hasOwnProperty("user_id")) {
      throw ApiException.fromResponse(response);
    }

    return User.fromJson(data); // Assuming data is the user object here
  }

  async setDerivId(userId, derivId) {
    console.info(`setting  deriv-id [${userId}]...`);
    const response = await this.sendRequest({
      endPoint: "set-deriv-id",
      method: "POST",
      data: {
        fetch: "user_data",
        deriv_id: derivId,
        user_id: userId,
        server_key: appId,
      },
      dataKey: "user_data",
      throwExeceptionOnFailed: true,
    });

    const { data } = response;
    if (!data || !data.hasOwnProperty("user_id")) {
      throw ApiException.fromResponse(response);
    }

    return User.fromJson(data); // Assuming data is the user object here
  }
}

export default ApiClient;
