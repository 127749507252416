class ApiException extends Error {
  constructor(apiResponse) {
    super(apiResponse.message);
    this.apiResponse = apiResponse;
    console.log(this.apiResponse);
    this.name = this.constructor.name;
  }

  get message() {
    
    return this.apiResponse.message || this.apiResponse.error.error_text;
  }

  get code() {
    return this.apiResponse.code < 400 ? 400 : this.apiResponse.code;
  }

  get error() {
    return this.apiResponse.error || {};
  }

  static fromResponse(apiResponse) {
    switch (apiResponse.code) {
      case 401:
        return new UnauthenticatedApiException(apiResponse);
      case 403:
        return new MissingAbilityApiException(apiResponse);
      case 404:
        return new ResourceNotFoundApiException(apiResponse);
      case 405:
        return new MethodNotAllowedApiException(apiResponse);
      case 422:
        return new ApiValidationException(apiResponse);
      default:
        return new ApiRequestFailure(apiResponse);
    }
  }

  toString() {
    return `${this.name} [${this.apiResponse.code}] : ${
      this.apiResponse.message
    }\n${JSON.stringify(this.apiResponse.error)}`;
  }

  errorFor(field) {
    return this.error[field] ? this.error[field][0] : null;
  }
}

class UnauthenticatedApiException extends ApiException {}
class MethodNotAllowedApiException extends ApiException {}
class ResourceNotFoundApiException extends ApiException {}
class MissingAbilityApiException extends ApiException {}
class ApiValidationException extends ApiException {}
class ApiRequestFailure extends ApiException {}

class CustomerDoesNotExistException extends Error {}
class EmailCannotBeEmptyLoginException extends Error {}
class PasswordCannotBeEmptyLoginException extends Error {}
class UserTokenNotUsedException extends Error {}

export {
  ApiException,
  UnauthenticatedApiException,
  MethodNotAllowedApiException,
  ResourceNotFoundApiException,
  MissingAbilityApiException,
  ApiValidationException,
  ApiRequestFailure,
  CustomerDoesNotExistException,
  EmailCannotBeEmptyLoginException,
  PasswordCannotBeEmptyLoginException,
  UserTokenNotUsedException,
};
